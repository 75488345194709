@import "~@csstools/normalize.css";
@import "~antd/dist/antd.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-menu-submenu > .ant-menu {
  background: none;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
  color: #fbe74d;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open {
  all: none;
  color: #fbe74d !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: none;
}
.ant-menu-submenu-selected {
  color: #fbe74d;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #fbe74d;
}

.ant-menu-inline {
  border: none;
}

.ant-menu-submenu-title {
  padding: 0 !important;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  font-size: 14px;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #fbe74d;
}

.ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
  background-color: #fbe74d;
}