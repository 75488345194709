html {
  scroll-behavior: smooth;
  cursor: default;
}

body {
  margin: 0;
  background: black;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  overflow-x:hidden;  /*для горизонтального*/
}

.App {
}

.decl {
  margin: 0 80px;
}

::-webkit-scrollbar {
  width: 5px;
  background: rgba(143, 143, 143, 0);
}

::-webkit-scrollbar-track {
  display: none;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2) inset;
}

::-webkit-scrollbar-thumb {
  background: #8f8f8f;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7c7c7c;
  transition: 0.3s;
}

.title {
  font-family: "Inria Serif", serif;
  margin: auto;
  font-size: 52px;
  line-height: 62px;
  user-select: none;
  text-transform: uppercase;
}

.color--white {
  color: #ffffff;
}

.wrap {
  display: flex;
  flex-flow: column nowrap;
  width: 100vw;
  height: 100vh;
}

.content {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

a:active,
a:hover,
a {
  text-decoration: none;

  color: #ffffff;
}
