.divider {
  box-sizing: border-box;
  width: 100%;
  height: 30vh;
}

.divider-bg {
  width: 100vw;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(
      127.48deg,
      rgba(0, 0, 0, 0.65) 52.8%,
      rgba(0, 0, 0, 0.3445) 133.33%
    ),
    url(/src/shared/img/biznes-rukopozhatie-sdelka-2789.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
}
